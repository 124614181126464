import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Flight Ticket App Template | Flight App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/flight-ticket-mobile-app/"
    metaDescription="Ready to spread your wings a little and design your own mobile app for boarding passes and flight tickets? Start prototyping with Uizard today."
    description="
    h2:Introducing SkyPlane, our boarding pass app design template
    <br/>
    Ready to spread your wings a little and design your own mobile app for boarding passes and flight tickets? Our brand new <a:https://uizard.io/templates/mobile-app-templates/>mobile app design template</a> comes packed with everything you could possibly need to design your own app or perfect your digital product design.
    <br/>
    h3:Fly me to the moon, and let me play among the stars
    <br/>
    Great news, your seats are reserved! Here is your one-way ticket to your dream design destination. We designed SkyPlane with ease of use in mind, our goal? To empower designers and non-designers alike to design their MVPs, products, and apps. 
    <br/>
    h3:Leaving on a jet plane, don't know when I'll be back again
    <br/>
    Our flight ticket app template for mobile is easy to use and even easier to modify and customize to suit your own brand guidelines or project brief. With Uizard, <a:https://uizard.io/wireframing/>UI wireframing</a> and prototyping is now easier than ever before. Sign up to Uizard Pro today and bring your design dreams to life.
    "
    pages={[
      "A clean flight ticket app landing page with a chic, streamlined aesthetic",
      "UI flows for user booking and seat selection",
      "Stunning screens for flight details and for boarding pass presentation and scanning",
    ]}
    projectCode="mK6bdPaP9mTjxboAKxod"
    img1={data.image1.childImageSharp}
    img1alt="mobile flight ticket app design overview"
    img2={data.image2.childImageSharp}
    img2alt="mobile flight ticket app design flight search screen"
    img3={data.image3.childImageSharp}
    img3alt="mobile flight ticket app design seat selector screen"
    img4={data.image4.childImageSharp}
    img4alt="mobile flight ticket app design ticket screen"
    img5={data.image5.childImageSharp}
    img5alt="mobile flight ticket app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/flight-booking-mobile-app/flight-booking-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/flight-booking-mobile-app/flight-booking-app-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/flight-booking-mobile-app/flight-booking-app-seat.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/flight-booking-mobile-app/flight-booking-app-ticket.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/flight-booking-mobile-app/flight-booking-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
